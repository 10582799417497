import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

export default () => {
  const authors = useBlogAuthors()

  return (
    <>
      <Seo title='About Us' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Who We Are ?'
          subheader='We are a promising well established company offering world class development services to meet upcoming technology challenges for individuals and corporate. We are offering one stop solutions for all our local clients for more than eight years. Now, we are already deep rooted with number of our international clients therefore, we have decided to introduce our top-notch development services globally through formal process. Professional and experienced team of eBuilderz are equipped with all modern resources possible along with years of experience and excellence in design and development services. Our experts are capable to practice their vast comprehension and skills to any stage of project whether in design or development.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author) => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </>
  )
}
